/**
 * File: base
 * Project: api-client
 * Author: lents
 * Date: 9/15/20
 *
 * This is the basic stuff external hosts need to figure out how to reach Captina
 *  - may be defined if lz_utils is included, hence the checks
 */
import './polyfills'
import { cpHost } from './vars'

const cpJavaScriptPath = '/lz/lz_js/'
export const requiredScripts = [
   'lz_utils_ui.js',
   'lz_utils.js',
   'lz_click_logger.js'
];

function loadRequiredScripts() {
   // check if requiredScripts are already loaded
   if ([...document.scripts].some(js => js.src && js.src.includes('lz_utils'))) {
      return;
   }

   // requireScripts aren't loaded, so load them
   requiredScripts.forEach(jsSrc => {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.crossOrigin = 'anonymous'
      script.src = `https://${cpHost}${cpJavaScriptPath}${jsSrc}`

      // add utils to head, others to body
      if (jsSrc.includes('lz_utils')) {
         document.head.appendChild(script)
      }
      else if (!document.body) {
         setTimeout(() => {
            document.body.appendChild(script)
         }, 5000)
      }
      else {
         document.body.appendChild(script)
      }
   })
}

loadRequiredScripts()
