// Used for CORS header
export const requestHost = location.hostname
// Used to send client params to server
export const queryString = location.search

// Get the backend host from the script src URL
export const cpHost = new URL(document.currentScript.src).hostname

// make it global
// XXX: not sure if this is needed
window.cpHost = cpHost

const cpPath = '/api/content/'
export const cpEndPoint = `https://${cpHost}${cpPath}`

